@import url("https://fonts.googleapis.com/css?family=Alfa+Slab+One|Anton|Indie+Flower|Lexend+Tera&display=swap");
@import url("https://fonts.googleapis.com/css?family=Sacramento|Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css?family=Calistoga&display=swap");
@import url("https://fonts.googleapis.com/css?family=Dancing+Script&display=swap");

body {
  display: flex;
  justify-content: center;
  background-image: url("https://i.imgur.com/tzdy6Pk.jpg");
  background-repeat: no-repeat;
  /* width: 60%;
  height: auto; */
  /* border: 1px solid green; */
  width: 100%;
  height: 100%;
}
/* @media (max-width: 500px) {
  body {
    display: flex;
    justify-content: center;
    width: 75%;
    height: 75%;
  }
} */

.mainbox {
  /* display: flex; */
  align-content: center;
  color: #f8f8ff;
  /* min-width: 100vh;
  min-height: 100vh;
  position: relative; */
  height: 250px;
  margin-top: 50px;
  /* border: 1px solid red; */
}
a {
  text-decoration: none;
  /* text-decoration: wavy underline #5f8a8b; */
  color: #f8f8ff;
}

.spinbox {
  margin-top: 45px;
  align-content: center;
  border: 1px solid red;
}

/* .spinning {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.introduction-name {
  display: flex;
  justify-content: center;
  font-size: 8rem;
  font-family: "Dancing Script", cursive;
  opacity: 0.6;
}

.introduction-name:hover {
  opacity: 25;
  transition: 0.3s;
  color: ;
}

.introduction-type {
  margin-top: 1%;
  font-family: "Lexend Tera", sans-serif;
}

.description {
  display: flex;
  font-family: "Lexend Tera", sans-serif;
}

.connect {
  display: flex;
  justify-content: center;
  /* position: absolute; */
  font-family: "Lexend Tera", sans-serif;
  /* border: 1px solid yellow; */
  margin: auto;
  padding: 25px;
  /* margin-right: 5%;
  padding-right: 25px; */
}

.connect-text {
  /* display: flex;
  align-content: flex-start;
  flex-direction: row; */
  padding: 10px;
  color: #f8f8ff;
  margin-bottom: 5em;
}

.email-connect {
  padding: 10px;
  opacity: 0.6;
  transition: 0.3s;
}

.email-connect:hover {
  opacity: 25;
}

.git-connect {
  padding: 10px;
  opacity: 0.6;
  transition: 0.3s;
}

.git-connect:hover {
  opacity: 25;
}

.link-connect {
  padding: 10px;
  margin-right: 15px;
  opacity: 0.6;
  transition: 0.3s;
}

.link-connect:hover {
  opacity: 25;
}

@media (max-width: 500px) {
  body {
    display: flex;
    flex-flow: column;
  }
  .mainbox {
    display: flex;
    justify-content: center;
    flex-flow: column;
  }
  .introduction-name {
    /* display: flex;
    justify-content: center; */
    margin-top: 75px;
    font-size: 70px;
  }
  .footer {
    justify-content: center;
    position: relative;
    bottom: 15px;
    width: 100%;
    height: 2.5rem;
    font-size: 11px;
    /* display: none; */
  }
  .connect {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .findme {
    display: none;
  }
}

/* .icon1 {
  align-self: center;
} */

/* img {
  max-width: 150px;
  height: 150px;
  position: relative;
  margin-top: 50px;
} */
/* .cube {
  align-self: center;
} */

.findme {
  position: relative;
  display: flex;
  flex-direction: row;

  margin-top: 9px;
}

/* .findme:hover {
  color: pink;
} */

.footer {
  position: absolute;
  bottom: 50px;
  width: 100%;
  height: 2.5rem;
}

/* 
body { 
    margin: 0; 
    height: 100vh;
}
  
canvas { 
    display: block;
}

h1 {
    position: absolute;
    top: 2em;
    left: 2em;
    font-family: 'Montserrat';
    font-size: 7em;
    text-transform: uppercase;
    width: auto;
    line-height: .8em;
    border: 5px solid black;
    padding: .2em;
  } */

/* font-family: "Lexend Tera", sans-serif; */
/* font-family: "Shadows Into Light", cursive; */

/* .threeD {
  color: darkgrey;
  white-space: nowrap;
  position: absolute;
  position: relative;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  transition: 0.3s;
  text-shadow: 1px 1px 0 grey, 1px 2px 0 grey, 1px 3px 0 grey, 1px 4px 0 grey,
    1px 5px 0 grey, 1px 6px 0 grey, 1px 7px 0 grey, 1px 8px 0 grey,
    5px 13px 15px black;
}

.threeD:hover {
  transition: 0.3s;
  transform: scale(1.1) translate(-50%, -50%);
  text-shadow: 1px -1px 0 grey, 1px -2px 0 grey, 1px -3px 0 grey,
    1px -4px 0 grey, 1px -5px 0 grey, 1px -6px 0 grey, 1px -7px 0 grey,
    1px -8px 0 grey, 5px -13px 15px black, 5px -13px 25px #808080;
} */
