#gear1,
#gear2,
#gear3 {
  color: #888;
  display: block;
  float: left;
  position: absolute;
}

#gear1 {
  top: 45px;
}

#gear2 {
  left: 60px;
}

#gear3 {
  top: 45px;
  left: 110px;
}

.spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.spin-back {
  -webkit-animation: spin-back 4s linear infinite;
  -moz-animation: spin-back 4s linear infinite;
  animation: spin-back 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin-back {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
