.maindiv {
  /* background-image: url("https://i.imgur.com/tzdy6Pk.jpg") !important;
  background-repeat: no-repeat; */
  color: white;
  /* border: 1px solid yellow; */
  display: flex;
  flex-flow: nowrap column;
  /* flex-direction: column; */
}

.introbox {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  /* border: 1px solid grey; */
  /* display: flex; */
  border-radius: 30px;
  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px gray;
  /* justify-content: flex-start; */
}
.threeD {
  margin-right: 25px;
}

.missionstatement {
  margin: 10px;
  padding: 15px;
}

.bottombox {
  position: absolute;
  bottom: 150px;
  display: flex;
}

.brightcard {
  padding: 5px;
  margin: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-content: center;
  border: 2px gray;
  border-radius: 30px;
  text-shadow: 1px 1px 2px black, 0 0 25px red, 0 0 5px gray;
}
p {
  display: flex;
  justify-content: center;
}

.mudcard {
  margin: 20px;
  padding: 5px;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  border: 2px gray;
  border-radius: 30px;
  text-shadow: 1px 1px 2px black, 0 0 25px red, 0 0 5px gray;
}
a {
  display: flex;
  align-self: center;
}
/* .link-connect {
  margin-top: 50px;
  padding: 20px;
} */

/* .linky {
  display: flex;
  align-self: center;
  padding: 10px;
  margin-right: 15px;
  opacity: 0.6;
  transition: 0.3s;
  color: yellow;
} */
.gohome {
  opacity: 25;
  /* color: dodgerblue; */
  width: 150px;
  padding: 10px;
  margin: 5px;
  border-radius: 33px;
  box-shadow: inset 0 0 10px #000000;
  font-weight: bold;
}
.gohome:hover {
  color: red;
  cursor: pointer;
  font-weight: bold;
}
.brtbtn {
  width: 150px;
  padding: 10px;
  margin: 5px;
  /* display: flex; */
  align-self: center;
  border-radius: 33px;
  box-shadow: inset 0 0 10px #000000;
  font-weight: bold;
}

.brtbtn:hover {
  cursor: pointer;
  color: red;
  font-weight: bold;
}

.brsbtn {
  width: 150px;
  padding: 10px;
  margin: 5px;
  align-self: center;
  border-radius: 33px;
  box-shadow: inset 0 0 10px #000000;
  font-weight: bold;
}
.brsbtn:hover {
  cursor: pointer;
  color: red;
  font-weight: bold;
}
.codebtn {
  width: 150px;
  padding: 10px;
  margin: 5px;
  align-self: center;
  border-radius: 33px;
  box-shadow: inset 0 0 10px #000000;
  font-weight: bold;
}
.codebtn:hover {
  color: red;
  cursor: pointer;
  font-weight: bold;
}

.dpsbtn {
  width: 150px;
  padding: 10px;
  margin: 5px;
  align-self: center;
  border-radius: 33px;
  box-shadow: inset 0 0 10px #000000;
  font-weight: bold;
}
.dpsbtn:hover {
  cursor: pointer;
  color: red;
  font-weight: bold;
}

/* .linky {
  opacity: 25;
  color: dodgerblue;
} */
/* .imgdiv {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.prjimage {
  height: 40%;
  width: 40%;
  border-radius: 40px;
  box-shadow: 5px 5px grey;
} */
@media (max-width: 500px) {
  .maindiv {
    display: flex;
    justify-content: center;
    flex-flow: column;
  }

  .introbox {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .threeD {
    display: flex;
    justify-content: center;
  }

  .gohome {
    display: none;
  }

  .bottombox {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-flow: row;
    position: absolute;
    bottom: 5px;

    height: 2.5rem;
  }
  .brightcard {
    display: flex;
    justify-content: center;
  }
}
