@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One|Anton|Indie+Flower|Lexend+Tera&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sacramento|Shadows+Into+Light&display=swap);
@import url(https://fonts.googleapis.com/css?family=Calistoga&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .App {
  text-align: center;
  background-color: #8c92ac;
} */

/* body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: black;
}

.deconstructed {
  position: relative;
  margin: auto;
  height: 0.71em;
  color: transparent;
  font-family: "Cambay", sans-serif;
  font-size: 10vw;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.03em;
}

.deconstructed > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffff64;
  pointer-events: none;
}

.deconstructed > div:nth-child(1) {
  -webkit-mask-image: linear-gradient(black 25%, transparent 25%);
  mask-image: linear-gradient(black 25%, transparent 25%);
  animation: deconstructed1 5s infinite;
}

.deconstructed > div:nth-child(2) {
  -webkit-mask-image: linear-gradient(
    transparent 25%,
    black 25%,
    black 50%,
    transparent 50%
  );
  mask-image: linear-gradient(
    transparent 25%,
    black 25%,
    black 50%,
    transparent 50%
  );
  animation: deconstructed2 5s infinite;
}

.deconstructed > div:nth-child(3) {
  -webkit-mask-image: linear-gradient(
    transparent 50%,
    black 50%,
    black 75%,
    transparent 75%
  );
  mask-image: linear-gradient(
    transparent 50%,
    black 50%,
    black 75%,
    transparent 75%
  );
  animation: deconstructed3 5s infinite;
}

.deconstructed > div:nth-child(4) {
  -webkit-mask-image: linear-gradient(transparent 75%, black 75%);
  mask-image: linear-gradient(transparent 75%, black 75%);
  animation: deconstructed4 5s infinite;
}

@keyframes deconstructed1 {
  0% {
    transform: translateX(100%);
  }
  26% {
    transform: translateX(0%);
  }
  83% {
    transform: translateX(-0.1%);
  }
  100% {
    transform: translateX(-120%);
  }
}

@keyframes deconstructed2 {
  0% {
    transform: translateX(100%);
  }
  24% {
    transform: translateX(0.5%);
  }
  82% {
    transform: translateX(-0.2%);
  }
  100% {
    transform: translateX(-125%);
  }
}

@keyframes deconstructed3 {
  0% {
    transform: translateX(100%);
  }
  22% {
    transform: translateX(0%);
  }
  81% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-130%);
  }
}

@keyframes deconstructed4 {
  0% {
    transform: translateX(100%);
  }
  20% {
    transform: translateX(0%);
  }
  80% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-135%);
  }
} */

.navbar {
  display: flex;
  flex-direction: column;
  background: pink;
}

.footer {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  justify-content: space-around; */
  /* background: teal; */

  position: relative;
  font-family: cursive;
}

.github {
  display: flex;
  padding: 50;
  color: red;
}

.linkedin {
  padding: 50;
  margin-left: 25;
}

.spiel {
  margin: auto;
  /* padding-top: 25; */
}

body {
  display: flex;
  justify-content: center;
  background-image: url("https://i.imgur.com/tzdy6Pk.jpg");
  background-repeat: no-repeat;
  /* width: 60%;
  height: auto; */
  /* border: 1px solid green; */
  width: 100%;
  height: 100%;
}
/* @media (max-width: 500px) {
  body {
    display: flex;
    justify-content: center;
    width: 75%;
    height: 75%;
  }
} */

.mainbox {
  /* display: flex; */
  align-content: center;
  color: #f8f8ff;
  /* min-width: 100vh;
  min-height: 100vh;
  position: relative; */
  height: 250px;
  margin-top: 50px;
  /* border: 1px solid red; */
}
a {
  text-decoration: none;
  /* text-decoration: wavy underline #5f8a8b; */
  color: #f8f8ff;
}

.spinbox {
  margin-top: 45px;
  align-content: center;
  border: 1px solid red;
}

/* .spinning {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.introduction-name {
  display: flex;
  justify-content: center;
  font-size: 8rem;
  font-family: "Dancing Script", cursive;
  opacity: 0.6;
}

.introduction-name:hover {
  opacity: 25;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: ;
}

.introduction-type {
  margin-top: 1%;
  font-family: "Lexend Tera", sans-serif;
}

.description {
  display: flex;
  font-family: "Lexend Tera", sans-serif;
}

.connect {
  display: flex;
  justify-content: center;
  /* position: absolute; */
  font-family: "Lexend Tera", sans-serif;
  /* border: 1px solid yellow; */
  margin: auto;
  padding: 25px;
  /* margin-right: 5%;
  padding-right: 25px; */
}

.connect-text {
  /* display: flex;
  align-content: flex-start;
  flex-direction: row; */
  padding: 10px;
  color: #f8f8ff;
  margin-bottom: 5em;
}

.email-connect {
  padding: 10px;
  opacity: 0.6;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.email-connect:hover {
  opacity: 25;
}

.git-connect {
  padding: 10px;
  opacity: 0.6;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.git-connect:hover {
  opacity: 25;
}

.link-connect {
  padding: 10px;
  margin-right: 15px;
  opacity: 0.6;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.link-connect:hover {
  opacity: 25;
}

@media (max-width: 500px) {
  body {
    display: flex;
    flex-flow: column;
  }
  .mainbox {
    display: flex;
    justify-content: center;
    flex-flow: column;
  }
  .introduction-name {
    /* display: flex;
    justify-content: center; */
    margin-top: 75px;
    font-size: 70px;
  }
  .footer {
    justify-content: center;
    position: relative;
    bottom: 15px;
    width: 100%;
    height: 2.5rem;
    font-size: 11px;
    /* display: none; */
  }
  .connect {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .findme {
    display: none;
  }
}

/* .icon1 {
  align-self: center;
} */

/* img {
  max-width: 150px;
  height: 150px;
  position: relative;
  margin-top: 50px;
} */
/* .cube {
  align-self: center;
} */

.findme {
  position: relative;
  display: flex;
  flex-direction: row;

  margin-top: 9px;
}

/* .findme:hover {
  color: pink;
} */

.footer {
  position: absolute;
  bottom: 50px;
  width: 100%;
  height: 2.5rem;
}

/* 
body { 
    margin: 0; 
    height: 100vh;
}
  
canvas { 
    display: block;
}

h1 {
    position: absolute;
    top: 2em;
    left: 2em;
    font-family: 'Montserrat';
    font-size: 7em;
    text-transform: uppercase;
    width: auto;
    line-height: .8em;
    border: 5px solid black;
    padding: .2em;
  } */

/* font-family: "Lexend Tera", sans-serif; */
/* font-family: "Shadows Into Light", cursive; */

/* .threeD {
  color: darkgrey;
  white-space: nowrap;
  position: absolute;
  position: relative;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  transition: 0.3s;
  text-shadow: 1px 1px 0 grey, 1px 2px 0 grey, 1px 3px 0 grey, 1px 4px 0 grey,
    1px 5px 0 grey, 1px 6px 0 grey, 1px 7px 0 grey, 1px 8px 0 grey,
    5px 13px 15px black;
}

.threeD:hover {
  transition: 0.3s;
  transform: scale(1.1) translate(-50%, -50%);
  text-shadow: 1px -1px 0 grey, 1px -2px 0 grey, 1px -3px 0 grey,
    1px -4px 0 grey, 1px -5px 0 grey, 1px -6px 0 grey, 1px -7px 0 grey,
    1px -8px 0 grey, 5px -13px 15px black, 5px -13px 25px #808080;
} */

#gear1,
#gear2,
#gear3 {
  color: #888;
  display: block;
  float: left;
  position: absolute;
}

#gear1 {
  top: 45px;
}

#gear2 {
  left: 60px;
}

#gear3 {
  top: 45px;
  left: 110px;
}

.spin {
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.spin-back {
  -webkit-animation: spin-back 4s linear infinite;
  animation: spin-back 4s linear infinite;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

#wrapper {
  width: 100%;
  max-width: 725px;
  margin: 0px auto;
}
.gears {
  width: 48%;
  display: inline-block;
}
.gears:first-child {
  margin-right: 3%;
}
.gears-container {
  width: 150px;
  height: 150px;
  font-size: 24px;
  padding: 9%;
  position: relative;
  margin: 5px auto;
}

.gear-rotate {
  width: 2em;
  height: 2em;
  top: 50%;
  left: 50%;
  margin-top: -1em;
  margin-left: -1em;
  background: #e9e581;
  position: absolute;
  border-radius: 1em;
  -webkit-animation: 1s gear-rotate linear infinite;
  animation: 1s gear-rotate linear infinite;
}
.gear-rotate-left {
  margin-top: -2.2em;
  top: 50%;
  width: 2em;
  height: 2em;
  background: #e9e581;
  position: absolute;
  border-radius: 1em;
  -webkit-animation: 1s gear-rotate-left linear infinite;
  animation: 1s gear-rotate-left linear infinite;
}

.gear-rotate::before,
.gear-rotate-left::before {
  width: 2.8em;
  height: 2.8em;
  background: linear-gradient(
      0deg,
      transparent 39%,
      #e9e581 39%,
      #e9e581 61%,
      transparent 61%
    ),
    linear-gradient(
      60deg,
      transparent 42%,
      #e9e581 42%,
      #e9e581 58%,
      transparent 58%
    ),
    linear-gradient(
      120deg,
      transparent 42%,
      #e9e581 42%,
      #e9e581 58%,
      transparent 58%
    );
  position: absolute;
  content: "";
  top: -0.4em;
  left: -0.4em;
  border-radius: 1.4em;
}
.gear-rotate::after,
.gear-rotate-left::after {
  width: 1em;
  height: 1em;
  background: #2b2b2b;
  position: absolute;
  content: "";
  top: 0.5em;
  left: 0.5em;
  border-radius: 0.5em;
}

/*
   * Keyframe Animations 
   */

@-webkit-keyframes gear-rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-180deg);
  }
}

@keyframes gear-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
}

@-webkit-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

@keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

.maindiv {
  /* background-image: url("https://i.imgur.com/tzdy6Pk.jpg") !important;
  background-repeat: no-repeat; */
  color: white;
  /* border: 1px solid yellow; */
  display: flex;
  flex-flow: nowrap column;
  /* flex-direction: column; */
}

.introbox {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  /* border: 1px solid grey; */
  /* display: flex; */
  border-radius: 30px;
  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px gray;
  /* justify-content: flex-start; */
}
.threeD {
  margin-right: 25px;
}

.missionstatement {
  margin: 10px;
  padding: 15px;
}

.bottombox {
  position: absolute;
  bottom: 150px;
  display: flex;
}

.brightcard {
  padding: 5px;
  margin: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-content: center;
  border: 2px gray;
  border-radius: 30px;
  text-shadow: 1px 1px 2px black, 0 0 25px red, 0 0 5px gray;
}
p {
  display: flex;
  justify-content: center;
}

.mudcard {
  margin: 20px;
  padding: 5px;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  border: 2px gray;
  border-radius: 30px;
  text-shadow: 1px 1px 2px black, 0 0 25px red, 0 0 5px gray;
}
a {
  display: flex;
  align-self: center;
}
/* .link-connect {
  margin-top: 50px;
  padding: 20px;
} */

/* .linky {
  display: flex;
  align-self: center;
  padding: 10px;
  margin-right: 15px;
  opacity: 0.6;
  transition: 0.3s;
  color: yellow;
} */
.gohome {
  opacity: 25;
  /* color: dodgerblue; */
  width: 150px;
  padding: 10px;
  margin: 5px;
  border-radius: 33px;
  box-shadow: inset 0 0 10px #000000;
  font-weight: bold;
}
.gohome:hover {
  color: red;
  cursor: pointer;
  font-weight: bold;
}
.brtbtn {
  width: 150px;
  padding: 10px;
  margin: 5px;
  /* display: flex; */
  align-self: center;
  border-radius: 33px;
  box-shadow: inset 0 0 10px #000000;
  font-weight: bold;
}

.brtbtn:hover {
  cursor: pointer;
  color: red;
  font-weight: bold;
}

.brsbtn {
  width: 150px;
  padding: 10px;
  margin: 5px;
  align-self: center;
  border-radius: 33px;
  box-shadow: inset 0 0 10px #000000;
  font-weight: bold;
}
.brsbtn:hover {
  cursor: pointer;
  color: red;
  font-weight: bold;
}
.codebtn {
  width: 150px;
  padding: 10px;
  margin: 5px;
  align-self: center;
  border-radius: 33px;
  box-shadow: inset 0 0 10px #000000;
  font-weight: bold;
}
.codebtn:hover {
  color: red;
  cursor: pointer;
  font-weight: bold;
}

.dpsbtn {
  width: 150px;
  padding: 10px;
  margin: 5px;
  align-self: center;
  border-radius: 33px;
  box-shadow: inset 0 0 10px #000000;
  font-weight: bold;
}
.dpsbtn:hover {
  cursor: pointer;
  color: red;
  font-weight: bold;
}

/* .linky {
  opacity: 25;
  color: dodgerblue;
} */
/* .imgdiv {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.prjimage {
  height: 40%;
  width: 40%;
  border-radius: 40px;
  box-shadow: 5px 5px grey;
} */
@media (max-width: 500px) {
  .maindiv {
    display: flex;
    justify-content: center;
    flex-flow: column;
  }

  .introbox {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .threeD {
    display: flex;
    justify-content: center;
  }

  .gohome {
    display: none;
  }

  .bottombox {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-flow: row;
    position: absolute;
    bottom: 5px;

    height: 2.5rem;
  }
  .brightcard {
    display: flex;
    justify-content: center;
  }
}

